import {
  ClassifyEducationResponse,
  GetEducationsResponse,
  RemoveClassificationResponse,
} from 'types/Education.type'

import api from '../api'
import { SunCode, SunCodeHierarchy } from 'types/SunCode.type'

const kompetensApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEducations: build.query<
      GetEducationsResponse,
      {
        approved?: boolean
        booked?: boolean
        text?: string
        category?: string
        page?: number
        take?: number
      }
    >({
      query: (arg) => {
        return {
          url: `/v1.0/kompetens/fcp`,
          method: 'POST',
          body: {
            approved: arg?.approved,
            booked: arg?.booked,
            text: arg?.text,
            category: arg?.category,
            page: arg?.page,
            take: arg?.take,
          },
        }
      },
      providesTags: ['Education'],
    }),
    classifyEducation: build.mutation<
      ClassifyEducationResponse,
      {
        id: string
        code: string
        name: string
      }
    >({
      query: ({ id, code, name }) => {
        return {
          url: `/v1.0/kompetens/fcp/${id}`,
          method: 'PUT',
          body: {
            code,
            name,
          },
        }
      },
      invalidatesTags: ['Education'],
    }),
    removeClassification: build.mutation<
      RemoveClassificationResponse,
      {
        id: string
      }
    >({
      query: ({ id }) => {
        return {
          url: `/v1.0/kompetens/fcp/remove/${id}`,
          method: 'PUT',
        }
      },
      invalidatesTags: ['Education'],
    }),
    getSunCodes: build.query<SunCode[], void>({
      query: () => {
        return {
          url: `/v1.0/kompetens/SunCode`,
          method: 'GET',
          params: { suncodeType: 'sun-education-field-2' },
        }
      },
      providesTags: ['Suncode'],
    }),
    getSunCodesHierarchy: build.query<SunCodeHierarchy[], void>({
      query: () => {
        return {
          url: `/v1.0/kompetens/SunCode/hierarchy`,
          method: 'GET',
        }
      },
      providesTags: ['SuncodeHierarchy'],
    }),
  }),
})

export const {
  endpoints,
  useGetEducationsQuery,
  useClassifyEducationMutation,
  useGetSunCodesQuery,
  useGetSunCodesHierarchyQuery,
  useRemoveClassificationMutation,
} = kompetensApi
