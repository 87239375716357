import React, { useState } from 'react'
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro'
import { Box, Button, Chip, Slide, SlideProps, Snackbar } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { formatRelative, parseISO } from 'date-fns'
import { sv } from 'date-fns/locale'
import { Education } from 'types'
import NoRowsOverlay from './components/NoRowsOverlay'
import { SunCodeSuggestion } from 'types/Education.type'
import { useGetEducationsQuery } from 'api/kompetens'
import { StatusChip } from 'components/StatusChip'
import {
  useClassifyEducationMutation,
  useRemoveClassificationMutation,
} from 'api/kompetens/kompetens'
import { RootState } from 'store/store'
import {
  closeClassificationSnackbar,
  openClassificationSnackbar,
} from 'api/slices/classificationSnackbarSlice'
import { useEpiContent } from '@trr/app-shell-data'
import { Content } from 'types/Content.type'

export interface SUNClassification {
  id: string
  name: string
  status: string
  sunCodeSuggestions: SunCodeSuggestion[]
}

interface EducationsListProps {
  searchQuery: string
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>
  filterList: string[]
  setFilterList: React.Dispatch<React.SetStateAction<string[]>>
  onlyShowUnclassified: boolean
  onlyShowBooked: boolean
}

const SlideTransition = (props: SlideProps) => {
  return <Slide {...props} direction="up" />
}

const EducationsList = ({
  searchQuery,
  setSearchQuery,
  filterList,
  setFilterList,
  onlyShowUnclassified,
  onlyShowBooked,
}: EducationsListProps) => {
  const {
    utbildningsklassificering: {
      educationNameHeader,
      educationCategoriesHeader,
      SUNClassificationHeader,
      createdDateHeader,
      rowsPerPageLabel,
      acceptButtonLabel,
      undoButtonLabel,
      snackbarMessage,
    },
  } = useEpiContent<Content>()
  const dispatch = useDispatch()
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  })
  const { isLoading, isFetching, data } = useGetEducationsQuery({
    approved: onlyShowUnclassified ? false : undefined,
    booked: onlyShowBooked ? true : undefined,
    text: searchQuery,
    category: filterList[0],
    page: paginationModel.page,
    take: paginationModel.pageSize,
  })

  const rows = data?.educations ?? []
  const [classifyEducation] = useClassifyEducationMutation()
  const [removeClassification] = useRemoveClassificationMutation()
  const classificationSnackbar = useSelector(
    (state: RootState) => state.classificationSnackbarSlice
  )

  const handleClassifyEducation = async (
    education: Education,
    code: string,
    name: string
  ) => {
    await classifyEducation({
      id: education.id,
      code,
      name,
    })

    // Show snackbar for confirming action
    dispatch(openClassificationSnackbar({ id: education.id }))
  }

  const handleRemoveClassification = async () => {
    if (classificationSnackbar.id)
      await removeClassification({
        id: classificationSnackbar.id,
      })
  }

  const columns: GridColDef[] = [
    { field: 'name', headerName: educationNameHeader, flex: 1 },
    {
      field: 'categories',
      headerName: educationCategoriesHeader,
      flex: 1,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderCell: (params: GridRenderCellParams<any, string[]>) =>
        params.value &&
        params.value.length > 0 &&
        params.value.map((el) => (
          <Chip
            data-testid="category-name"
            key={el}
            label={el}
            sx={{ mr: 1.5 }}
            onClick={() => setFilterList([el])}
          />
        )),
    },
    {
      field: 'sunClassification',
      headerName: SUNClassificationHeader,
      flex: 1,
      minWidth: 420,
      valueGetter: (_, row: Education): Education => row,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderCell: (params: GridRenderCellParams<any, Education>) =>
        params.value && <StatusChip education={params.value} />,
    },
    {
      field: 'accept',
      headerName: '',
      flex: 1,
      maxWidth: 103,
      valueGetter: (_, row: Education): boolean => {
        return (
          row.sunCodeSuggestions &&
          row.sunCodeSuggestions.length > 0 &&
          !row.sunCodeApproved
        )
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderCell: (params: GridRenderCellParams<any, boolean>) => {
        const education = params.row as Education
        return params.value ? (
          <strong>
            <Button
              data-testid="accept-button"
              onClick={() => {
                void handleClassifyEducation(
                  education,
                  education.sunCodeSuggestions?.[0]?.code,
                  education.sunCodeSuggestions?.[0]?.name
                )
              }}
              variant="outlined"
              size="small"
            >
              {acceptButtonLabel}
            </Button>
          </strong>
        ) : null
      },
    },
    {
      field: 'createdDate',
      headerName: createdDateHeader,
      flex: 1,
      maxWidth: 200,
      headerAlign: 'right',
      align: 'right',
      valueGetter: (createdDate: string) => {
        if (!createdDate) return '-'
        return formatRelative(parseISO(createdDate), Date.now(), {
          locale: sv,
        })
      },
    },
  ]

  const handleSnackbarClose = () => dispatch(closeClassificationSnackbar())

  return (
    <Box sx={{ width: '100%' }}>
      <DataGridPro
        rows={rows}
        columns={columns}
        loading={isLoading || isFetching}
        rowHeight={76}
        autoHeight
        rowCount={data?.totalHits ?? 0}
        pagination
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        slotProps={{
          pagination: {
            labelRowsPerPage: rowsPerPageLabel,
          },
          row: {
            'data-testid': 'grid-row',
          },
        }}
        pageSizeOptions={[10, 20, 30]}
        sx={{
          '&, .MuiDataGrid-root': { border: 'none' },
          '&, .MuiDataGrid-footerContainer': { border: 'none' },
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
          '--DataGrid-overlayHeight': '500px',
        }}
        slots={{
          noRowsOverlay: () => (
            <NoRowsOverlay
              setFilterList={setFilterList}
              setSearchQuery={setSearchQuery}
            />
          ),
        }}
        disableRowSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        disableColumnSorting
        disableColumnSelector
      />
      <Snackbar
        open={classificationSnackbar.open}
        onClose={handleSnackbarClose}
        TransitionComponent={SlideTransition}
        message={snackbarMessage}
        data-testid="classification-snackbar"
        action={
          <Button
            data-testid="undo-button"
            onClick={() => void handleRemoveClassification()}
            variant="text"
            color="inherit"
            size="small"
          >
            {undoButtonLabel}
          </Button>
        }
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={10000}
      />
    </Box>
  )
}

export default EducationsList
