import {
  combineReducers,
  configureStore,
  PreloadedState,
} from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import { api } from '../api'
import activeEducationSlice from 'api/slices/activeEducationSlice'
import educationDrawerSlice from 'api/slices/educationDrawerSlice'
import classificationSnackbarSlice from 'api/slices/classificationSnackbarSlice'

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  ['activeEducationSlice']: activeEducationSlice,
  ['educationDrawerSlice']: educationDrawerSlice,
  ['classificationSnackbarSlice']: classificationSnackbarSlice,
})

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([api.middleware]),
    preloadedState,
  })

const store = setupStore()

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof rootReducer>

export default store
