import React, { ReactElement } from 'react'
import { Box, ClickAwayListener, Popper, PopperProps } from '@mui/material'

interface CustomPopperProps extends PopperProps {
  open: boolean
  anchor: HTMLElement
  children: ReactElement
  onClickAway: () => void
}

export const CustomPopper = ({
  open,
  anchor,
  onClickAway,
  children,
  ...props
}: CustomPopperProps) => {
  return (
    <>
      <Popper
        open={open}
        placement="bottom-start"
        data-testid="custom-popper"
        anchorEl={anchor}
        modifiers={[
          {
            name: 'flip',
            enabled: false,
          },
        ]}
        sx={{ zIndex: 3 }}
        {...props}
      >
        <ClickAwayListener onClickAway={onClickAway}>
          <Box>{children}</Box>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

export default CustomPopper
