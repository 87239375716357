import React from 'react'
import AssistantRounded from '@mui/icons-material/AssistantRounded'
import { Box, Stack, Typography, Divider, Chip, useTheme } from '@mui/material'
import { SunCodeSuggestion } from 'types/Education.type'
import { RootState } from 'store/store'
import { useDispatch, useSelector } from 'react-redux'
import { useClassifyEducationMutation } from 'api/kompetens/kompetens'
import { openClassificationSnackbar } from 'api/slices/classificationSnackbarSlice'
import { useEpiContent } from '@trr/app-shell-data'
import { Content } from 'types/Content.type'

const SunCodeSuggestionsList = ({
  closePopper,
}: {
  closePopper: () => void
}) => {
  const {
    utbildningsklassificering: { suggestionSunCodes, chooseFromAISuggestions },
  } = useEpiContent<Content>()

  const theme = useTheme()
  const dispatch = useDispatch()
  const activeEducation = useSelector(
    (state: RootState) => state.activeEducationSlice
  )
  const { sunCodeSuggestions, id } = activeEducation
  const [trigger] = useClassifyEducationMutation()

  const selectSunCode = async (element: SunCodeSuggestion) => {
    const { name, code } = element

    await trigger({
      id,
      code,
      name,
    })

    // Show snackbar for confirming action
    dispatch(openClassificationSnackbar({ id }))

    // Close popper
    closePopper()
  }

  return (
    <Box data-testid="sun-code-suggestions-list">
      <Stack direction={'row'} pt={3} pb={1} alignItems={'center'}>
        <AssistantRounded
          sx={{
            fill: theme.palette.primary.dark,
            width: '16px',
            height: '16px',
          }}
        />
        <Typography ml={1} variant="body1" color={theme.palette.primary.dark}>
          <strong>{suggestionSunCodes}</strong>
        </Typography>
        <Typography
          ml={'auto'}
          variant="body2"
          color={theme.palette.text.secondary}
        >
          {chooseFromAISuggestions}
        </Typography>
      </Stack>
      <Divider />
      <Stack pt={2} pb={2} gap={2}>
        {sunCodeSuggestions.map((element) => {
          const isFirstItem = sunCodeSuggestions?.[0]?.code === element?.code

          return (
            <Chip
              onClick={() => void selectSunCode(element)}
              icon={
                isFirstItem ? (
                  <AssistantRounded
                    sx={{
                      fill: theme.palette.primary.dark,
                      width: '16px',
                      height: '16px',
                    }}
                  />
                ) : (
                  <></>
                )
              }
              key={element.code}
              sx={{
                color: theme.palette.primary.dark,
                background: isFirstItem
                  ? theme.palette.surface?.purple
                  : 'none',
                paddingLeft: isFirstItem ? '8px' : '0px',
                border: isFirstItem ? 'none' : '',
              }}
              label={
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  component={'span'}
                >
                  <Typography variant="subtitle2" pr={1}>
                    <strong>{element.code}</strong>
                  </Typography>
                  <Typography variant="subtitle2">{element.name}</Typography>
                </Box>
              }
            />
          )
        })}
      </Stack>
    </Box>
  )
}

export default SunCodeSuggestionsList
