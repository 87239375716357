import { SunCode, SunCodeHierarchy } from 'types/SunCode.type'

const fuzzySearch = (sunCodes: SunCode[], searchQuery: string) => {
  const match = new RegExp(`${searchQuery}`, 'gi')

  const searchCode = sunCodes.filter((el) => match.test(el.code))
  const searchName = sunCodes.filter((el) => match.test(el.name))

  return [...searchCode, ...searchName]
}

const fuzzySearchHierarchy = (
  sunCodes: SunCodeHierarchy[],
  searchQuery: string
) => {
  const match = new RegExp(`${searchQuery}`, 'gi')

  const searchCode = sunCodes.filter((el) =>
    el.children.some((child) => match.test(child.code))
  )
  const searchName = sunCodes.filter((el) =>
    el.children.some((child) => match.test(child.name))
  )

  return [...searchCode, ...searchName]
}

export { fuzzySearch, fuzzySearchHierarchy }
